<template>
  <div id="service">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'Service',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{
    goPersonalService(){
      this.$router.push('/service/personal')
    }
  },
}
</script>

<style scoped>
#service{
  width: 100vw;
  height: 100vh;
  background: #212429;
  transition: .6s;
  box-sizing: border-box;
}

</style>
